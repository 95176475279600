
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number] as PropType<string | number>,
      required: true,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
  },
});
